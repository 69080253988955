.qx-widget-article-price {
    background-color: #e7e7e7;
    border-radius: 4px;
    color: #000 !important;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 12px !important;
    font-weight: 700;
    line-height: 25px !important;
    margin: 4px !important;
    padding: 0px 8px;
    position: relative;
    z-index: 1;
}
.qx-heading .qx-widget-article-price {
    background-color: white;
    border: solid;
    border-radius: 3px;
    border-width: 1px 1px 5px;
    padding: 2px 4px;
    border-color: #a3a3a3;

}
.qx-heading.qx-article .qx-widget-article-price {
    border-width: 1px 1px 7px;
    font-size: 18px !important;
    padding: 5px 6px;
}
.qx-heading .qx-widget-article-price.qx-heading-priceup {
    border-color: #25ca83;
    background-color: #6CC69F !important;
    color: #25ca83;
}
.qx-heading .qx-widget-article-price.qx-heading-pricedown {
    border-color: #f34860;
    background-color: #FCDFE3 !important;
    color: #f34860;
}

.qx-widget-article-price .qx-widget-popup,
.qx-widget-article-price:hover .qx-widget-popup {
    display: block;
}
.qx-widget-price-section {
    color: #fff;
    display: flex;
    flex-direction: row;
    font-size: 10px;
    font-weight: 700;
    justify-content: center;
    margin-top: -11px;
    padding: 0 1px 0 4px;
    position: relative;
}
.qx-widget-price-section span {
    margin-right: 5px;
    padding: 0 2px;
    white-space: nowrap;
    line-height: 10px;
}
.qx-widget-divider-line-bottom,
.qx-widget-divider-line-top,
.qx-widget-price-section .qx-widget-divider-line {
    border-top: 1.4px dashed #9e9e9e;
    height: 3px;
    left: 55px;
    opacity: 0.4;
    position: absolute;
    top: 30px;
    width: 56%;
}
.qx-widget-price-section .qx-widget-divider-line-top {
    top: 10px;
}
.qx-widget-price-section .qx-widget-divider-line-bottom {
    top: 52px;
}
.qx-widget-brand-section {
    align-items: center;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    line-height: 1;
    padding: 2px 12px;
}
.qx-widget-demand-left {
    display: flex;
    align-items: center;
}
.qx-widget-demand-left span {
    font-size: 10px;
    line-height: 11px !important;
    margin-right: 3px;
}
.qx-widget-brand-section .qx-widget-brand-name {
    align-items: center;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 3px 6px;
}
.qx-widget-brand-section .qx-widget-brand-name b {
    color: #00ffff;
    text-decoration: underline;
    line-height: 11px;
}
.qx-widget-brand-section .qx-widget-brand-name span {
    font-size: 10px;
    font-weight: 400 !important;
    line-height: 10px !important;
    color: white !important;
}
@keyframes priceRise {
    0% {
        background-color: initial;
    }
    5%,
    15%,
    25% {
        background-color: #3ca64633;
    }
    10%,
    20%,
    30% {
        background-color: #3ca64680;
    }
    35%,
    to {
        background-color: "#3CA646";
    }
}
.qx-widget-priceUp {
    animation-duration: 2s;
    animation-name: priceRise;
}
@keyframes priceFall {
    0% {
        background-color: initial;
    }
    5%,
    15%,
    25% {
        background-color: #ff413633;
    }
    10%,
    20%,
    30% {
        background-color: #ff413680;
    }
    35%,
    to {
        background-color: "#F34860";
    }
}
.qx-widget-priceDown {
    animation-duration: 2s;
    animation-name: priceFall;
}
.qx-widget-popup-up {
    color: #6CC69F;
}
.qx-widget-popup-down {
    color: #FCDFE3;
}
.qx-widget-more-section {
    color: #fff;
    font-size: 10px;
    font-weight: 700;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 18px;
    margin-right: 8px;
    z-index: 10;
}
.qx-widget-more-section {
    display: flex;
    flex-direction: row;
}
.qx-widget-filters {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-top: 8px;
}

.qx-widget-filters li {
    align-items: center !important;
    color: #ccc;
    display: flex;
    flex: 100% !important;
    font-size: 12px;
    font-weight: 700;
    justify-content: center !important;
    line-height: 14px !important;
    padding: 1px !important;
    min-width: 22px !important;
    padding: 0 2px;
    width: 28px !important;
    white-space: nowrap;
}
.qx-widget-filters li,
.qx-widget-filters li.active {
    border: none !important;
    cursor: pointer;
    margin: 10px 8px !important;
}
.qx-widget-filters li.active {
    background-color: #cccccc;
    border-radius: 2px !important;
    color: #000;
    padding: 0 1px !important;
}
.qx-widget-expand-icon {
    margin-right: 14px;
}
.qx-widget-expand-icon span {
    font-size: 24px;
    font-weight: 700;
    display: flex;
    align-items: center;
}
.qx-widget-accuracy {
    align-items: center;
    color: #9c9c9c;
    display: flex;
    font-size: 10px;
    font-weight: 700;
    justify-content: flex-end;
    margin-bottom: 0;
    margin-right: 15px;
    margin-top: -7px;
}
.qx-widget-demand-accuracy .qx-widget-accuracy-num {
    font-size: 9px;
    line-height: 11px;
}
.qx-widget-chart-y-label {
    top: 42px;
    left: -10px;
    transform: rotate(270deg);
}
.qx-widget-chart-x-label,
.qx-widget-chart-y-label {
    background-color: #000;
    border-radius: 20px;
    color: #9c9c9c;
    font-size: 7px;
    font-weight: 700;
    line-height: 10px !important;
    padding: 0;
    position: absolute;
}
.qx-widget-chart-x-label {
    left: 125px;
    top: 84px;
}
.qx-widget-demand-right a {
    text-decoration: none !important;
    line-height: 10px !important;
}

.ripple {
    position: relative;
    display: inline-block;
}
.ripple:before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    border-radius: 4px;
    opacity: 0;
}
.ripple:after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    border-radius: 4px;
    z-index: 0;
    opacity: 0;
}



.up.ripple:after {
    animation: greenanimate 1.5s 0.75s linear 10;

}
.down.ripple:after {
    animation: redanimate 1.5s 0.75s linear 10;
}

.up.ripple:before {
    animation: greenanimate 1.5s linear 10;

}
.down.ripple:before {
    animation: redanimate 1.5s linear 10;
}

@keyframes greenanimate {
    0% {
        transform: scale(0.8);
        opacity: 1;
        border: 1.5px solid rgba(200, 200, 200, 0.8);
    }
    100% {
        transform: scale(1.5);
        opacity: 0;
        border: 1.5px solid rgba(200, 200, 200, 0.8);
    }
}

@keyframes redanimate {
    0% {
        transform: scale(0.8);
        opacity: 1;
        border: 1.5px solid rgba(200, 200, 200, 0.8);
    }
    100% {
        transform: scale(1.5);
        opacity: 0;
        border: 1.5px solid rgba(200, 200, 200, 0.8);
    }
}

/*# sourceMappingURL=index.6e657a14.css.map */
.qx-foldershape {
    width: 327px;
    clip-path: path(
        "M20 0H113.678C116.527 0 119.342 0.608356 121.936 1.78436L155.5 17H321.5C324.261 17 326.5 19.2386 326.5 22V193C326.5 195.761 324.261 198 321.5 198H5C2.23858 198 0 195.761 0 193V20C0 8.9543 8.95431 0 20 0Z"
    );
}

.qx-widget-demandBarDiv {
    align-items: center;
    display: flex;
}
.qx-widget-demand-accuracy {
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 12px;
    background-color: #0e121d;
}

.qx-widget-brand-name a {
    text-decoration: none !important;
    line-height: 10px !important;
    font-size: 10px !important;
}

.fireIcon {
    position: absolute;
    z-index: 5;
    right: -3px;
    top: -5px;
}
.freshbadge {
    position: absolute;
    z-index: 5;
    bottom: -11px;
    right: 4px;
}

.qx-widget-article-no-price {
    display: inline-block;
    font-size: 13px !important;
    font-weight: 600;
    line-height: 25px !important;
    margin: 4px !important;
    padding: 4px 8px;
    position: relative;
    z-index: 1;
    background-color: white;
    border: solid;
    border-radius: 3px;
    border-width: 1px 1px 5px;
    border-color: #a3a3a3 !important;
    color: #686868 !important;
}
