$optionDefaultColours: #ED5565,#FC6E51,#FFCE54,#2ECC71,#5D9CEC,#AC92EC;

body {
   // display:flex;
   // flex-direction:row;
   // justify-content:center;
   // align-items:center;
//    overflow:hidden;
//    height:100vh;
   // transition:.25s;
//    @include dark {
//       background: #232223;
//       color:white;
//    }
   .credit {
      position: absolute;
      bottom:20px;
      left:20px;

      color:inherit;
   }
   .options {
      display:flex;
      flex-direction:row;
      align-items:stretch;
      overflow:hidden;
      min-width:900px;
      max-width:1000px;
      // width:calc(100% - 0px);
      height:400px;
      transition: all .9s linear;

      @for $i from 1 through 4 {
         @media screen and (max-width:798px) {
            min-width:400px;
            // .option:nth-child(#{6-$i}) {
            //    display:none;
            // }
         }
      }

      .option {
         position: relative;
         overflow:hidden;
         min-width:60px;
         margin:0 8px;
         background:var(--optionBackground, var(--defaultBackground, #E6E9ED));
         background-size:auto 120%;
         background-position:center;
         cursor: pointer;
         transition:1.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
         -webkit-border-radius:30px;
         -moz-border-radius:30px;
         border-radius:30px;
         overflow: hidden;

         @media screen and (min-width:798px) {
            min-width: 80px;
            margin:0 11px;
         }

         @for $i from 1 through length($optionDefaultColours) {
            &:nth-child(#{$i}) {
               // --defaultBackground: #{nth($optionDefaultColours, $i)};
               --defaultBackground: rgba(50,50,50,0.9)
            }
         }
         &.active {
            flex-grow:1000;
            transform:scale(1);
            max-width:660px;
            min-width: 250px;
            margin:0px;
            border-radius:40px;
            background-size:auto 100%;
            // .shadow {
            //    box-shadow:inset 0 -120px 120px -120px black, inset 0 -120px 120px -100px black;
            // }
            .label {
               top:80px;
               left:10px;


               .info >div {
                  top: 140px;
                  left: 0px;
                  opacity:1;
                  max-width: 210px;

                  @media screen and (min-width:798px) {
                     top: 90px;
                     left: 0px;
                     opacity:1;
                     max-width: 350px;
                  }
               }
            }

            .label .icon {
               display: none;
            }

            /*&:active {
               transform:scale(0.9);
            }*/
         }
         &:not(.active) {
            flex-grow:1;
            border-radius:30px;
            .shadow {
               bottom:-40px;
               box-shadow:inset 0 -120px 0px -120px black, inset 0 -120px 0px -100px black;
            }
            .label {
               bottom:10px;
               left:10px;
               @media screen and (min-width:798px) {
                  left: 20px;

               }
               .info >div {
                  left:20px;
                  opacity:0;
               }
            }
         }
         .shadow {
            position: absolute;
            bottom:0px;
            left:0px;
            right:0px;

            height:120px;

            transition:1.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
         }
         .label {
            display:flex;
            position: absolute;
            right:0px;

            height:40px;
            transition:1.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
            .icon {
               display:flex;
               flex-direction:row;
               justify-content:center;
               align-items:center;

               min-width:40px;
               max-width:40px;
               height:40px;
               border-radius:100%;

               background-color:#fff;
               border: 1px solid #00FFFF;
               color:var(--defaultBackground);
            }
            .info {
               display:flex;
               flex-direction:column;
               justify-content:center;
               margin-left:10px;
               color:white;

               // white-space: pre;
               >div {
                  position: relative;

                  transition:1.5s cubic-bezier(0.05, 0.61, 0.41, 0.95), opacity .5s ease-out;
               }


               .sub {
                  transition-delay:.1s;
               }
            }
         }
      }
   }
}