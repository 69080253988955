@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Inter;
    scroll-behavior: smooth;
}
::-webkit-scrollbar {
    height: 8px;
    background: #000;

    width: 8px;
    border-radius: 900px;
}

::-webkit-scrollbar-thumb {
    background: #1b1d21;

    border-radius: 900px;
}

::-webkit-scrollbar-corner {
    background: #000;
}
#root {
    width: 100%;
    height: 100%;
    background-color: "#42495B";
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.table-class {
    padding-bottom: 20px !important;
    background-color: red;
}

.react-calendar .react-calendar__navigation {
    padding-bottom: 28px;
}

.react-calendar .react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
    padding-left: 10px;
    font-size: 20px;
}

.react-calendar .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
    padding-right: 10px;
    font-size: 20px;
}

.react-calendar .react-calendar__navigation__arrow.react-calendar__navigation__next2-button,
.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
    display: none;
}

.react-calendar .react-calendar__month-view__days button {
    padding: 6px 2px;
}

.react-calendar .react-calendar__tile {
    display: flex !important;
    flex-basis: auto !important;
    justify-content: center;
    align-items: center;
    margin: 5px 8px;
    width: 20px;
    height: 20px;
    padding: 2px;
    font-size: 14px;
}

.react-calendar__month-view__weekdays__weekday {
    margin-bottom: 8px;
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
    color: #000;
}

.react-calendar.calender-dark .react-calendar__month-view__weekdays__weekday abbr {
    color: #fff !important;
}

.react-calendar .react-calendar__tile {
    width: 30px;
    height: 28px;
}

.react-calendar .react-calendar__tile:hover {
    border: 1px solid #73a3c8;
    background-color: #73a3c8 !important;
    border-radius: 50%;
    color: #fff !important;
}

.react-calendar.calender-dark .react-calendar__tile:hover {
    border: 1px solid #73a3c8;
    background-color: #73a3c8 !important;
    border-radius: 50%;
    color: #b3ffff !important;
}

.react-calendar .react-calendar__tile.react-calendar__tile--active {
    background-color: #00ffff !important;
    color: #000 !important;
    border-radius: 50% !important;
    font-size: 13px;
    padding: 1px;
}
.react-calendar.calender-light .react-calendar__tile.react-calendar__tile--active {
    background-color: #1d6ba6 !important;
    color: #fff !important;
    padding: 9px 1px !important;
}

.react-calendar__navigation {
    display: flex;
    justify-content: space-between;
    max-width: 320px;
}

.react-calendar .react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
.react-calendar .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
    padding-left: 0px;
    width: 25px;
    padding-right: 10px;
}

.react-calendar .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
    padding-left: 10px;
}

.react-calendar .react-calendar__navigation__label {
    max-width: 190px;
    flex-grow: 0;
}

.calender-dark {
    color: white !important;
}
.react-calendar__tile.react-calendar__decade-view__years__year.single-tile {
    width: 50px !important;
    padding: 10px 20px;
    border-radius: 6px !important;
    cursor: pointer;
}

.react-calendar__tile.react-calendar__year-view__months__month.single-tile {
    width: 90px !important;
    padding: 10px 20px;
    border-radius: 6px !important;
    cursor: pointer;
}

.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth.single-tile {
    color: #aaa;
    cursor: pointer;
}

.react-calendar__tile.react-calendar__century-view__decades__decade.single-tile {
    width: 135px;
    border-radius: 10px;
    cursor: pointer;
}

button.react-calendar__tile.react-calendar__month-view__days__day.single-tile:disabled {
    color: gray;
    cursor: not-allowed;
}

button.react-calendar__tile.react-calendar__month-view__days__day.single-tile:disabled:hover {
    background-color: transparent !important;
    cursor: not-allowed;
}
