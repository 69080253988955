h1.user-data {
    /* background: linear-gradient(91.91deg, #00FF66 8.25%, #0085FF 89.51%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text; */
    /* // text-fill-color: transparent; */
}

.shadow-inner {
    --tw-shadow: inset -16px -91px 82px 82px rgb(0 0 0 / 0.7);
    --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}